<script setup>
import { WdsSearchFormInline, WdsSearchFormStacked, WdsSearchFormWizard } from '@wds/components';
import searchConfig from '~/config/search';

// ### STORE/PINIA
const searchStore = useSearchStore();
const locationStore = useLocationStore();

// ### COMPOSABLES
const dateTime = useDateTime();
const searchData = useSearch();

// ### COMPUTED
const searchFormInlineParams = computed(() => ({
  size: 'large',
  ...searchData.getSearchFormCommonParams.value,
}));

const searchFormWizardParams = computed(() => ({
  activeStep: searchData.wizardActiveStep.value,
  openWizard: searchData.wizardIsOpen.value,
  focusDestinationOnOpen: true,
  ...searchData.getSearchFormCommonParams.value,
}));

const searchFormStackedParams = computed(() => ({
  size: 'small',
  ...searchData.getSearchFormCommonParams.value,
}));

const getDestinationObject = computed(() => {
  const locationType = locationStore.getLocationType;
  const locationInfo = locationStore.getLocationFullInfo;
  switch (locationType) {
    case 'city':
      return {
        id: locationInfo.city?.id || 0,
        type: 'city',
        label: locationInfo.city?.name,
        labelSmall: locationInfo.country?.name,
        value: locationInfo.city?.id || 0,
      };
    case 'district':
      return {
        id: locationInfo.district?.id || 0,
        cityId: locationInfo.city?.id || 0,
        type: 'citydistricts',
        label: locationInfo.district?.name,
        labelSmall: [locationInfo.city?.name, locationInfo.country?.name].join(', '),
        value: locationInfo.district?.id || 0,
      };
    case 'property':
      return {
        id: locationInfo?.id || 0,
        type: 'property',
        label: locationInfo.property?.name,
        value: locationInfo.property?.id || 0,
      };
    default:
      return {};
  }
});

// ### CREATED
searchStore.setSearchCheckIn(dateTime.tomorrow);
searchStore.setSearchCheckOut(dateTime.addDaysToDate(dateTime.tomorrow, searchConfig.defaultNights));
searchStore.setSearchParam('location', getDestinationObject.value);
</script>

<template>
  <div class="search-form-wrapper">
    <WdsSearchFormStacked
      class="search-form-stacked-container"
      v-bind="searchFormStackedParams"
      @destination-selected="searchData.handleDestinationFieldClicked"
      @destination-input="searchData.handleDestinationSearch"
      @destination-item-selected="searchData.handleDestinationItemSelected"
      @recent-destination-selected="searchData?.handleRecentDestinationItemSelected"
      @current-location="searchData.handleCurrentLocation"
      @dates-selected="searchData.handleDatesFieldClicked"
      @guests-selected="searchData.handleGuestsFieldClicked"
      @guests-change="searchData.handleGuestsNumberChanged"
      @guests-group-type-change="searchData.handleGuestsGroupTypeChanged"
      @guests-group-ages-change="searchData.handleGuestsGroupAgesChanged"
      @search-form-submit="searchData.handleSubmit"
    />
    <WdsSearchFormInline
      class="search-form-inline-container"
      v-bind="searchFormInlineParams"
      @destination-selected="searchData.handleDestinationFieldClicked"
      @destination-input="searchData.handleDestinationSearch"
      @destination-item-selected="searchData.handleDestinationItemSelected"
      @recent-destination-selected="searchData?.handleRecentDestinationItemSelected"
      @current-location="searchData.handleCurrentLocation"
      @dates-selected="searchData.handleDatesFieldClicked"
      @guests-selected="searchData.handleGuestsFieldClicked"
      @guests-change="searchData.handleGuestsNumberChanged"
      @guests-group-type-change="searchData.handleGuestsGroupTypeChanged"
      @guests-group-ages-change="searchData.handleGuestsGroupAgesChanged"
      @search-form-submit="searchData.handleSubmit"
    />
    <WdsSearchFormWizard
      class="search-form-wizard-container"
      v-bind="searchFormWizardParams"
      @destination-selected="searchData.handleDestinationItemSelected"
      @destination-input="searchData.handleDestinationSearch"
      @destination-item-selected="searchData.handleDestinationItemSelected"
      @recent-destination-selected="searchData?.handleRecentDestinationItemSelected"
      @current-location="searchData.handleCurrentLocation"
      @guests-change="searchData.handleGuestsNumberChanged"
      @guests-group-type-change="searchData.handleGuestsGroupTypeChanged"
      @guests-group-ages-change="searchData.handleGuestsGroupAgesChanged"
      @search-form-submit="searchData.handleSubmit"
      @search-form-close="searchData.handleWizardClose"
    />
  </div>
</template>

<style scoped lang="scss">
.search-form-wrapper {
  width: 100%;

  .search-form-inline-container {
    display: none;
    margin: 0 auto;
  }

  @include tablet-large {
    .search-form-stacked-container,
    .search-form-wizard-container {
      display: none;
    }

    .search-form-inline-container {
      display: block;
      max-width: wds-rem(1024px);
    }
  }
}
</style>
